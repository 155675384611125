import React, { useEffect, useState } from 'react'
import './DataGridTable.scss'
import { ReactComponent as ArrowDownBlack } from '../../../components/assets/images/ArrowDown_Black.svg'
import { ReactComponent as ArrowDownWhite } from '../../../components/assets/images/ArrowDown.svg'

const DataGridTable = ({ 
    facturasRows, 
    ordersRows, 
    facturasColumns, 
    ordersColumns, 
    ref, 
    checkboxSelection = false, 
    rowSelectionModel, 
    onRowSelectionModelChange,
    fontSize=16
  }) => {


  const [isRowOpen, setIsRowOpen] = useState([])
  const hasOrdersInFactura = (factura) => {
    if (factura.orders.length > 0) {
      const facOrdersId = factura.orders
      const ordersIdList = ordersRows.map((el) => el._id)
      // console.log(ordersIdList.some((el) => facOrdersId.includes(el)));
      return ordersIdList.some((el) => facOrdersId.includes(el))
    } else {
      return false
    }
  }
  const isAllFactNoOrders = facturasRows.map((el) => hasOrdersInFactura(el)).filter((el) => el !== false).length === 0
  

  useEffect(() => {
    console.log(isRowOpen);
  }, [isRowOpen])
  const listOrdersId = facturasRows.flatMap(item => item.orders.map(order => order))
  const ordersWithoutFacturas = ordersRows.filter(order => listOrdersId.includes(order._id) === false)
  // console.log(ordersWithoutFacturas);
  const [isOrdersWithoutFacShow, setIsOrdersWithoutFacShow] = useState(true)

  return (
    <div className='DataGridTable__container'>
      <div
        ref={ref}
        className='DataGridTable'
        style={{ fontSize: `${fontSize}px` }}
      >
        {ordersWithoutFacturas.length > 0 && <>
          <div className='DataGridTable__row__head' style={{ cursor: 'pointer', width: '100%', background: '#286F29' }} onClick={() => setIsOrdersWithoutFacShow(!isOrdersWithoutFacShow)}>
            
            <ArrowDownWhite width={25} height={25} style={{ transform:  isOrdersWithoutFacShow ? 'rotate(180deg)' : '', transition: '.3s ease-in-out' }}/>
            <div className='DataGridTable__row__head__title'>
              Pendientes de facturar
            </div>
          </div>
          {isOrdersWithoutFacShow && <>
          <div className='DataGridTable__row__head'>
            <ArrowDownBlack width={25} height={25} style={{ opacity: 0}}/>
            {ordersColumns.map((ordersColumn, index) => (
              <div key={index} style={{ width: ordersColumn.width, height: '50px'}} className='DataGridTable__row__head__column'>{ordersColumn.headerName}</div>
            ))}
          </div>
          {ordersWithoutFacturas.map((orderRow, i) => (
            <div className='DataGridTable__row'>
              {checkboxSelection && (
                <div className='DataGridTable__row__column'>
                  <input 
                    type="checkbox" 
                    className='checkbox'
                    onChange={(e) => {
                      const newRowSelectionModel = e.target.checked
                        ? [...rowSelectionModel, orderRow]
                        : rowSelectionModel.filter(value => value._id !== orderRow._id);
                      onRowSelectionModelChange(newRowSelectionModel);
                    }}
                    checked={rowSelectionModel.map((el) => el?._id).includes(orderRow._id) } 
                  />
                </div>
              )}
              {ordersColumns.map((ordersColumn, c) => (
                <div className='DataGridTable__row__column' key={c} style={{ flexBasis: ordersColumn.width, height: '50px'}}>
                  {ordersColumn?.renderCell ? ordersColumn.renderCell({value: orderRow[ordersColumn.field], row: orderRow}) : orderRow[ordersColumn.field]}
                </div>
              ))}
            </div>
          ))}</>}
        </>}
        <div className='DataGridTable__row__head'>
            <div className='DataGridTable__row__head__title'>
              {isAllFactNoOrders ? 'No hay facturas pendientes de facturar' : 'Facturas'}
            </div>
          </div>
        {/* <div className='DataGridTable__row__head'>
          <ArrowDown width={25} height={25} style={{ opacity: 0}}/>
          {facturasColumns.map((column, index) => (
            <div className='DataGridTable__row__head__column' key={index} style={{ flexBasis: column.width, height: '50px'}}>{column.headerName}</div>
          ))}
        </div> */}
        {facturasRows.map((row, i) => (hasOrdersInFactura(row) && <div key={i}>
          <div className='DataGridTable__row' onClick={() => {
              if (isRowOpen == row._id) setIsRowOpen([]); else setIsRowOpen(row._id)
            }}>
            <ArrowDownBlack width={25} height={25} style={{ transform:  isRowOpen == row._id ? 'rotate(180deg)' : '', transition: '.3s ease-in-out' }}/>
            {facturasColumns.map((columns, index) => (
              <div className='DataGridTable__row__column' key={index} style={{ flexBasis: columns.width, height: '50px'}}>
                {columns.renderCell ? columns.renderCell({value: row[columns.field], row: row}) : row[columns.field]}
              </div>
            ))}
          </div>
          {isRowOpen == row._id &&
            <div className='DataGridTableOrders '>
              <div className='DataGridTable__row__head'>
                {checkboxSelection && (
                  <div className='DataGridTable__row__head__column'>
                    <input type="checkbox" onChange={(e) => {
                      onRowSelectionModelChange(ordersRows.filter(el => row.orders.includes(el._id)).length == rowSelectionModel.length ? [] : ordersRows.filter(el => row.orders.includes(el._id)));
                    }} checked={ordersRows.filter(el => row.orders.includes(el._id)).length == rowSelectionModel.length} />
                  </div>
                )}
                {ordersColumns.map((ordersColumn, index) => (
                  <div className='DataGridTable__row__head__column' key={index} style={{ flexBasis: ordersColumn.width, height: '50px'}}>{ordersColumn.headerName}</div>
                ))}
              </div>
              {ordersRows.filter(el => row.orders.includes(el._id)).map((orderRow, i) => (
                <div className='DataGridTable__row'>
                  {checkboxSelection && (
                    <div className='DataGridTable__row__column'>
                      <input 
                        type="checkbox" 
                        onChange={(e) => {
                          const newRowSelectionModel = e.target.checked
                            ? [...rowSelectionModel, orderRow]
                            : rowSelectionModel.filter(value => value._id !== orderRow._id);
                          onRowSelectionModelChange(newRowSelectionModel);
                        }}
                        checked={rowSelectionModel.map((el) => el?._id).includes(orderRow._id) } 
                      />
                    </div>
                  )}
                  {ordersColumns.map((ordersColumn, c) => (
                    <div className='DataGridTable__row__column' key={c} style={{ flexBasis: ordersColumn.width, height: '50px'}}>
                      {ordersColumn?.renderCell ? ordersColumn.renderCell({value: orderRow[ordersColumn.field], row: orderRow}) : orderRow[ordersColumn.field]}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          }
        </div>
        ))}
      </div>
    </div>
  )
}

export default DataGridTable