import React, { useMemo } from 'react'
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  document: {
    display: 'block',
  },
  page: {
    flexDirection: 'column',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  view: {
    border: '1 solid grey',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    margin: '0 20',
    border: '1 solid grey',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: '1 solid grey',
  },
  tableHeaderСell: {
    flex: 1,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  tableHeaderСellLong: {
    flex: 3,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  tableBottom: {
    display: 'flex',
    flexShrink: 0,
    margin: '0 20',
    border: '1 solid grey',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderTop: 'none',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    margin: '0 20',
    borderBottom: '1 solid grey',
  },
  cell: {
    flex: 1,
    padding: 5,
    fontSize: 11,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
  },
  cellLong: {
    flex: 3,
    padding: 5,
    fontSize: 11,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
    borderLeft: '1 solid grey',
    display: 'flex',
    flexDirection: 'column',
  },
  textTitle: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
  },
  orderTitle: {
    marginLeft: 20,
    marginBottom: 10,
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '600',
  },
  textTitleRight: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'right',
  },
  textTitleRightBold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'right',
  },
  textTitleBold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  },
  flexView: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    margin: 20,
    justifyContent: 'flex-end',
  },
  textTitleTotal: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'right',
  },
})

const Albaran = ({
  orders, // Передаем orders вместо items
  items,
  order,
  name,
  value,
  contact,
  adress,
  date,
  number,
  numberInYear,
  IBAN,
}) => {
  console.log(items)
  console.log(orders)

  const getTotalbyIva = (items, iva) => {
    return items.filter((item) => item.IVA === iva).reduce((acc, curr) => acc + curr.price * (curr.quantity || 0) * (iva / 100), 0)
  }

  const getTotalbyIvaAll = (items) => {
    return items.reduce((acc, curr) => acc + curr.price * (curr.quantity || 0), 0)
  }

  const getTotalbyIvaTotal = (items, iva) => {
    const newItems = items.filter((item) => item.IVA === iva)
    if (newItems.length === 0) return 0
    return newItems.reduce((acc, curr) => acc + curr.price * (curr.quantity || 0), 0)
  }

  const total = (items) => {
    return items.reduce((acc, curr) => acc + curr.price * (curr.quantity || 0), 0)
  }

  // const items = orders.flatMap((order) => order.items)

  const counters = useMemo(() => {
    const counters = {
      0: 0,
      2: 0,
      4: 0,
      5: 0,
      10: 0,
      21: 0,
    }

    for (const order of orders) {
      for (const item of order.items) {
        counters[item.IVA] += 1
      }
    }

    return counters
  }, [orders])

  const IVA0Counter = counters[0]
  const IVA2Counter = counters[2]
  const IVA4Counter = counters[4]
  const IVA5Counter = counters[5]
  const IVA10Counter = counters[10]
  const IVA21Counter = counters[21]

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        {/* Header Section */}
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          }}
        >
          <View style={{ display: 'flex', flexDirection: 'column', gap: 30 }}>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={styles.textTitleBold}>
                {contact?.name || order?.userAcc?.name || order?.name || 'N/A'}({order?.tradeName || contact?.tradeName || ''})
              </Text>
              <Text style={styles.textTitle}>{contact?.nif ? contact?.nif : ''}</Text>
              <Text style={styles.textTitle}>{contact?.billAddress?.address || order?.address || ''}</Text>
            </View>
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={styles.textTitle}>
                <Text style={{ ...styles.textTitleBold, textTransform: 'uppercase' }}>Factura </Text>
                <Text style={{ textTransform: 'capitalize' }}>
                  {order?.tradeName || contact?.tradeName || 'N/A'}
                  {number}
                  {date.month() + 1 < 10 ? '0' + (date.month() + 1) : date.month() + 1}
                  {date.year().toString().substr(-2)}/{numberInYear}
                </Text>
              </Text>
              <Text style={styles.textTitle}>
                <Text style={{ ...styles.textTitleBold, textTransform: 'uppercase' }}>Fecha </Text>
                <Text style={{ textTransform: 'capitalize' }}>{date.format('DD.MM.YYYY').toString()}</Text>
              </Text>
            </View>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', width: 200, alignItems: 'flex-end' }}>
            <Text style={styles.textTitleRightBold}>{name}</Text>
            <Text style={styles.textTitleRight}>{value}</Text>
            <Text style={styles.textTitleRight}>{adress}</Text>
            <Text style={styles.textTitleRight}>602 179 471</Text>
          </View>
        </View>

        {/* Table for Items */}
        {orders.map((order) => (
          <View key={order.id}>
            <Text style={styles.orderTitle}>Order #{order.particalId}</Text>
            <View style={styles.table}>
              <View style={styles.tableHeader}>
                <Text style={styles.tableHeaderСellLong}>CONCEPTO</Text>
                <Text style={styles.tableHeaderСell}>Cantidad</Text>
                <Text style={styles.tableHeaderСell}>UND</Text>
                <Text style={styles.tableHeaderСell}>PRECIO</Text>
                <Text style={styles.tableHeaderСell}>IVA</Text>
                <Text style={styles.tableHeaderСell}>TOTAL</Text>
              </View>
              {order.items.map((item) => (
                <View wrap={false} key={item.sku} style={styles.row}>
                  <View style={styles.cellLong}>
                    <Text style={{ fontSize: 11, fontFamily: 'Helvetica' }}>{item?.name}</Text>
                    <Text style={{ color: '#9D9D9D', fontSize: 12, fontFamily: 'Helvetica', fontWeight: 'bold' }}>{item?.sku}</Text>
                  </View>
                  <Text style={styles.cell}>{item?.quantity ? item?.quantity.toFixed(2) : 'N/A'}</Text>
                  <Text style={styles.cell}>{item?.unit ? item?.unit : 'N/A'}</Text>
                  <Text style={styles.cell}>{item?.marketPriceCP ? item?.marketPriceCP : 'N/A'}€</Text>
                  <Text style={styles.cell}>{item?.IVA ? item?.IVA : 0}%</Text>
                  <Text style={styles.cell}>{item?.marketPriceCP ? parseFloat(item?.marketPriceCP * item?.quantity).toFixed(2) : 'N/A'}€</Text>
                </View>
              ))}
            </View>
          </View>
        ))}

        {/* Totals Section */}
        <View wrap={false} style={styles.flexView}>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>BASE IMPONIBLE:</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 0) / (100 + 0)) * 100).toFixed(2)}€</Text>}
            {IVA2Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 2) / (100 + 2)) * 100).toFixed(2)}€</Text>}
            {IVA4Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 4) / (100 + 4)) * 100).toFixed(2)}€</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 5) / (100 + 5)) * 100).toFixed(2)}€</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 10) / (100 + 10)) * 100).toFixed(2)}€</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 21) / (100 + 21)) * 100).toFixed(2)}€</Text>}
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>
              {(
                (getTotalbyIvaTotal(items, 0) / (100 + 0)) * 100 +
                (getTotalbyIvaTotal(items, 2) / (100 + 2)) * 100 +
                (getTotalbyIvaTotal(items, 4) / (100 + 4)) * 100 +
                (getTotalbyIvaTotal(items, 5) / (100 + 5)) * 100 +
                (getTotalbyIvaTotal(items, 10) / (100 + 10)) * 100 +
                (getTotalbyIvaTotal(items, 21) / (100 + 21)) * 100
              ).toFixed(2)}
              €
            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>IMPUESTO</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>IVA 0%</Text>}
            {IVA2Counter > 0 && <Text style={styles.textTitleTotal}>IVA 2%</Text>}
            {IVA4Counter > 0 && <Text style={styles.textTitleTotal}>IVA 4%</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>IVA 5%</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>IVA 10%</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>IVA 21%</Text>}
            {/* <Text style={styles.textTitleTotal}>TOTAL:</Text> */}
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>TOTAL IMPUESTO</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>{'0.00'}€</Text>}
            {IVA2Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 2) / (100 + 2)) * 100 * 0.02).toFixed(2)}€</Text>}
            {IVA4Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 4) / (100 + 4)) * 100 * 0.04).toFixed(2)}€</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 5) / (100 + 5)) * 100 * 0.05).toFixed(2)}€</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 10) / (100 + 10)) * 100 * 0.1).toFixed(2)}€</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>{((getTotalbyIvaTotal(items, 21) / (100 + 21)) * 100 * 0.21).toFixed(2)}€</Text>}
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>TOTAL</Text>
            {IVA0Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 0).toFixed(2)}€</Text>}
            {IVA2Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 2).toFixed(2)}€</Text>}
            {IVA4Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 4).toFixed(2)}€</Text>}
            {IVA5Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 5).toFixed(2)}€</Text>}
            {IVA10Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 10).toFixed(2)}€</Text>}
            {IVA21Counter > 0 && <Text style={styles.textTitleTotal}>{getTotalbyIvaTotal(items, 21).toFixed(2)}€</Text>}
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>{getTotalbyIvaAll(items).toFixed(2)}€</Text>
          </View>
        </View>
      </Page>
    </Document>
  )
}

export default Albaran
