import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Button, CircularProgress, Switch, useMediaQuery } from '@mui/material'

import { mainApi } from '../../../../store/services/mainApi'
import StyledBox from '../../StyledBox/StyledBox'
import { DataGrid, GridPagination, GridToolbar, GridToolbarContainer, GridToolbarExport, useGridApiContext } from '@mui/x-data-grid'
import { ReactComponent as Like } from '../../../components/assets/images/Like.svg'
import { ReactComponent as InProgress } from '../../../components/assets/images/inProgress.svg'
import { ReactComponent as Production } from '../../../components/assets/images/cap.svg'
import { ReactComponent as Delivery } from '../../../components/assets/images/scooterDelivery.svg'
import { ReactComponent as CONGEDOR } from '../../../components/assets/images/CongeladorFP.svg'
import { Box } from '@mui/material'
import swipeLeft from '../../../components/assets/images/swipe-left-icon.svg'

import { usePDF } from '@react-pdf/renderer'
import Albaran from '../../PrintDocuments/Albaran/Albaran'
import FastOrder from '../../StaticPage/FastOrder/FastOrder'
import { useDispatch, useSelector } from 'react-redux'
import { setPriceIvaMode } from '../../../../store/slices/contextUI'
import { useRef } from 'react'

export const AlbaranPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)

  const [orderId, setOrderId] = useState(location.pathname.split('/').pop())

  const mobile = useMediaQuery('(max-width: 768px)')
  const {
    data: order,
    isLoading,
    isError,
  } = mainApi.useGetOrdersByIdQuery(orderId, {
    pollingInterval: 50000,
  })

  const [deliveryDate, setDeliveryDate] = useState('')
  const [creationDate, setCreationDate] = useState('')
  const [creationTime, setCreationTime] = useState('')
  const [items, setItems] = useState([])
  const [rows, setRows] = useState([])

  useEffect(() => {
    if (order) {
      document.title = `Order ${order._id.slice(-6)} ${order.tradeName || order.userAcc?.tradeName || 'No TradeName'}`
      // Устанавливаем deliveryDate после получения order
      setDeliveryDate(order.deliveryDate ? order.deliveryDate : '')

      // Устанавливаем items после получения order
      setItems(order.items)

      // Устанавливаем дату и время создания после получения order
      const date = new Date(order.date)
      const localeDate = date.toLocaleDateString() // Получаем локализованную дату
      const localeTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) // Получаем локализованное время

      setCreationDate(localeDate)
      setCreationTime(localeTime)

      // Устанавливаем rows после получения order
      setRows(order.items)
    }
  }, [order])

  const statusBackground = (status) => {
    switch (status) {
      case 'Ready':
        return <Like width={20} height={20} />
      case 'PROCESSING':
        return <InProgress width={20} height={20} />
      case 'NOT AVAILABLE':
        return '⛔'
      case 'CHECKING':
        return '👁️'
      case 'PRODUCTION':
        return <Production width={20} height={20} /> //Production
      case 'SHOPPING':
        return <Delivery fill='#6D26E1' width={20} height={20} />
      case 'CONGEDOR':
        return <CONGEDOR width={20} height={20} />
      case 'DEVOLUCION':
        return '🔙'
      case 'CONFIRMAR':
        return '👍'
      default:
        return null
    }
  }

  const [openDialog, setOpenDialog] = useState(false)
  const [statusName, setStatusName] = useState('')

  const handleCellClick = (params, event) => {
    if (params.field === 'status') {
      setStatusName(params.row.status.name) // предполагается, что статус находится в params.row.status
      setOpenDialog(true)
    }
  }

 

  const total = useMemo(() => {
    if (order && order.items) {
      if (order?.deliveryPrice) {
        return (
          order.deliveryPrice +
          order.items
            .map((item) => (item.marketPriceCP !== '' ? 
            !ivaSwitch ? parseFloat(item.marketPriceCP * (item.quantity || 1) / (1+item.IVACP/100)) : parseFloat(item.marketPriceCP * (item.quantity || 1)) 
            : 0))
            .reduce((acc, curr) => acc + curr, 0)
        )
      } else {
        return order.items
          .map((item) => (item.marketPriceCP !== '' ?
          !ivaSwitch ? parseFloat(item.marketPriceCP * (item.quantity || 1) / (1+item.IVACP/100)) : parseFloat(item.marketPriceCP * (item.quantity || 1)) 
          : 0))
          .reduce((acc, curr) => acc + curr, 0)
      }
    }
    return 0 // return a default value if items is falsy
  }, [order, ivaSwitch])

  const IVA = useMemo(() => {
    let totalIva = 0
    if (order && order.items) {
      totalIva = order.items
        .map((item) => (item.IVA ? (item.IVA / 100) * (item.marketPriceCP * (item.quantity || 1)) : 0))
        .reduce((acc, curr) => acc + curr, 0) // Provide 0 as the initial value
    }
    console.log(totalIva, 'IVA')
    // if (order.items && order.items.length > 0) {
    //   totalIva = totalIva / order.items.length
    // }
    return totalIva ? totalIva : 0 // return a default value if items is falsy
  }, [order])

  //albaran
  const [instancePDf, updatePDF] = usePDF({
    document: <Albaran items={[]} order={{}} contact={{}} />,
  })

  const [printModal, setPrintModal] = useState(false)

  const itemsForAlbaran = useMemo(() => {
    if (order?.items) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      order.items.forEach((item) => {
        const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

        if (!uniqueItemsMap[key]) {
          // Если элемент с таким ключом еще не существует, добавляем его в объект
          uniqueItemsMap[key] = {
            conceptio: item.name,
            sku: item.sku,
            quantity: item.quantity,
            price: item.marketPriceCP,
            IVA: item.IVA ? item.IVA : 0,
            tipo: item.currentUnit ? item.currentUnit : '',
            total: (item.marketPriceCP * item.quantity).toFixed(2),
          }
        } else {
          // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
          uniqueItemsMap[key].quantity += item.quantity
          uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity).toFixed(2)
        }
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object.values(uniqueItemsMap)

      return uniqueItems
    } else {
      return []
    }
  }, [order])

  useEffect(() => {
    if (order) {
      updatePDF(<Albaran items={itemsForAlbaran} order={order} contact={order?.userAcc} />)
    }
  }, [order, itemsForAlbaran])

  const balance = useMemo(() => {
    if (order) {
      return parseFloat(order?.paidValue)
        ? (
            (parseFloat(order?.deliveryPrice) ? parseFloat(order.deliveryPrice) : 0) +
            order.items
              .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              .reduce((acc, curr) => acc + curr, 0)
              .toFixed(2) -
            (parseFloat(order?.paidValue).toFixed(2) || 0)
          ).toFixed(2)
        : (
            (parseFloat(order?.deliveryPrice) ? parseFloat(order.deliveryPrice) : 0) +
            order.items
              .map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              .reduce((acc, curr) => acc + curr, 0)
          ).toFixed(2)
    }
  }, [order])


  const urlParams = new URLSearchParams(window.location.search);
  const albrandDownload = urlParams.get('albranddownload');
  const albaranRef = useRef()
  useEffect(() => {
    console.log('ff');
    if (albrandDownload && albaranRef) {
      console.log('ав');
      albaranRef?.current?.click()
    }
  }, [urlParams, albaranRef])

  return isLoading ? (
    <CircularProgress />
  ) : (
    <div
      style={{ width: '95%', margin: '20px auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: mobile ? 'column' : 'row',
          gap: '20px',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <StyledBox defaultVisible={true} sx={{ width: 'max-content', minWidth: '300px', maxWidth: '100%' }}>
          <div className='orderInfo__box__item'>
            <p>
              ORDER: <span>{order?.idTucomida ? order.idTucomida : order?._id ? order?._id.slice(-6) : ''}</span>
            </p>
          </div>

          <div className='orderInfo__box__item'>
            <p>
              PAYMENT TYPE:{' '}
              <span>
                {order?.paymentType !== '-'
                  ? parseFloat(order?.paymentType)
                    ? 'Сдача с ' + order?.paymentType + '€'
                    : order?.paymentType
                  : 'Без сдачи'}
              </span>
            </p>
          </div> 
          <div className='orderInfo__box__item'>
            <p>
              Debt: <span style={{ color: balance <= 0 ? 'green' : 'red', textAlign: 'right' }}>{balance}€</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              TVA: <span>{IVA.toFixed(2)}&euro;</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Delivery: <span>{order?.deliveryPrice ? order?.deliveryPrice : '0.00'}&euro;</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Paid: <span>{(parseFloat(order?.paidValue) ? parseFloat(order?.paidValue).toFixed(2) : '0.00') + '€'}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              CREATION:{' '}
              <span>
                {creationDate} {creationTime}
              </span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              DELIVERY: <span>{deliveryDate ? deliveryDate : ''}</span>
              <span>{deliveryDate && deliveryDate.includes(',') ? (deliveryDate.split(',')[1].slice(0, -6) > 12 ? '🌛' : '🌞') : ''}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              TOTAL ITEMS: <span>{items ? items.length : 0}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              COMMENT:<span>{order?.description ? order?.description : 'No comment'}</span>
            </p>
          </div>
        </StyledBox>

      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10px 0',
        }}
      >
        <p>STATUS: </p>
        <p
          style={{
            textTransform: 'capitalize',
            padding: '5px',
            borderRadius: '5px',
            backgroundColor: order?.status?.color ? order?.status?.color : '#fff',
            border: '1px solid ' + (order?.status?.color ? order?.status?.color : '#fff'),
            margin: '0 5px',
          }}
        >
          {order?.status.name ? order?.status.name : 'In progress'}
        </p>
        
        
        <a
          href={instancePDf.url}
          download={`Albaran ${order?.idTucomida ? order?.idTucomida : order?._id ? order?._id.slice(-6) : 'N/A'}.pdf`}
          target='_blank'
          rel='noreferrer'
          ref={albaranRef}
          style={{ width: '300px', padding: '5px 10px', color: '#fff', borderRadius: '10px', cursor: 'pointer', backgroundColor: '#5FC56D', fontFamily: 'Montserrat', fontWeight: 'bold', fontSize: '16px', height: '60px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          Descargar Albarán 🖨️
        </a>
      </div>
      
    </div>
  )
}
