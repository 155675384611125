import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { Badge, Button, CircularProgress, Container, Modal, Typography, useMediaQuery } from '@mui/material'

import { mainApi } from '../../../../store/services/mainApi'
import { Box } from '@mui/material'
import { Link } from '@mui/material'

import { usePDF } from '@react-pdf/renderer'
import Albaran from '../../PrintDocuments/Albaran/Albaran'
import { useRef } from 'react'
import dayjs from 'dayjs'
import PDS from '../../PrintDocuments/Facturas/PDS'
import FacturaDetail from '../../PrintDocuments/Facturas/FacturaDetail'
import { useTranslation } from 'react-i18next'
import StyledBox from './../../StyledBox/StyledBox';


export default function FacturaPage() {
  const [tradeName, setTradeName] = useState('')
  const navigate = useNavigate()

  //localization
  const { t } = useTranslation()
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [viewOrder, setViewOrder] = useState(false)
  const [viewOrderId, setViewOrderId] = useState('')

  const location = useLocation()
  
  const tradeNameParams = useParams().tradeName ;
  const idParams = useParams().id;
  const {
    data: contact,
    isLoading,
    isError,
  } = mainApi.useGetBuisnessContactByTradeNameQuery(tradeNameParams, {
    skip: !tradeNameParams,
  })

  const [reqOrders, setReqOrders] = useState([])
  const [rowValue, setRowValue] = useState([])
  
  const { data: ordersForDetail, isLoading: detailLoading } = mainApi.useGetOrdersByIdsQuery(reqOrders, {
    skip: !reqOrders.length,
    refetchOnMountOrArgChange: true,
  })
  //facturas table
  
   
  const [instanceFacturaDetail, updateFacturaDetail] = usePDF({
    document: (
      <FacturaDetail
        orders={[]}
        items={[]}
        order={{}}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={{}}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={dayjs()}
        number={''}
        numberInYear={''}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })

  const {
    data: facturas,
    isLoading: isLoadingFacturas,
    isFetching: isFetchingFacturas,
  } = mainApi.useGetFacturasByClientQuery(
    { tradeName: contact?.tradeName },
    {
      skip: !contact,
    },
  )

  const [foundFactura, setFoundFactura] = useState({})

  useEffect(() => {
    if (!isLoading) {
      setFoundFactura(facturas.find((item) => item._id == idParams));
      console.log(facturas);
    }
  }, [facturas])
  
  
  const [openDummy, setOpenDummy] = useState(false)

  const [pdfName, setPdfName] = useState('')

  const setValuesForPdf = (value) => {
    setRowValue(value)
    setReqOrders(value.orders)
    console.log(value.orders, 'somesome')
    setOpenDummy(true)
    setPdfName(value.cliente + '/' + value.fetcha.toISOString() + '.pdf')
    if (value.cuenta === 'Sergey Fedorenko') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
          contact={value.contact}
          name={'Sergey Fedorenko'}
          value={'X7031676R'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
        />,
      )
    } else if (value.cuenta === 'Claudia Cires') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
          contact={value.contact}
          name={'Claudia Cires'}
          value={'Y4155866X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
        />,
      )
    } else if (value.cuenta === 'Moldovenesque SL') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
          contact={value.contact}
          name={'Moldovenesque SL'}
          value={'B67505446'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
        />,
      )
    } else if (value.cuenta === 'Alexandru Clim') {
      console.log('Alexandru Clim clicked')
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
          contact={value.contact}
          name={'Alexandru Clim'}
          value={'Z0107137P'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES51 0081 0168 9100 0198 9510'}
        />,
      )
    } else if (value.cuenta === 'Roman Horokhov') {
      updatePDF(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Passeig Calvell 27, Barcelona, 08005'}
          contact={value.contact}
          name={'Roman Horokhov (VPF)'}
          value={'Y3467154X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
        />,
      )
    }
  }

  const setValuesForDetail = (value) => {
    if (ordersForDetail) {
      if (value.cuenta === 'Sergey Fedorenko') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
            contact={value.contact}
            name={'Sergey Fedorenko'}
            value={'X7031676R'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
          />,
        )
      } else if (value.cuenta === 'Claudia Cires') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
            contact={value.contact}
            name={'Claudia Cires'}
            value={'Y4155866X'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
          />,
        )
      } else if (value.cuenta === 'Moldovenesque SL') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
            contact={value.contact}
            name={'Moldovenesque SL'}
            value={'B67505446'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
          />,
        )
      } else if (value.cuenta === 'Alexandru Clim') {
        console.log('Alexandru Clim clicked')
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
            contact={value.contact}
            name={'Alexandru Clim'}
            value={'Z0107137P'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES51 0081 0168 9100 0198 9510'}
          />,
        )
      } else if (value.cuenta === 'Roman Horokhov') {
        updateFacturaDetail(
          <FacturaDetail
            orders={ordersForDetail}
            items={value.items}
            order={value.order}
            adress={'Passeig Calvell 27, Barcelona, 08005'}
            contact={value.contact}
            name={'Roman Horokhov (VPF)'}
            value={'Y3467154X'}
            date={dayjs(value.fetcha)}
            number={value.number}
            numberInYear={value.numberInYear}
            IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
          />,
        )
      }
    }
  }


  useEffect(() => {
    if (tradeName === null || tradeName === undefined) {
      console.log('tradeName is null or undefined.')
    } else if (tradeName === 'VPF') {
      fetch('https://tpomobi.shop/getOrdersAdmin?tradeName=VPF', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((response) => response.json()).then((error) => {
        console.error('Error:', error)
      })
    }
  }, [tradeName])

  useEffect(() => {
    if (contact) {
      // document.title = `${contact.name}`
      const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link')
      favicon.rel = 'icon'
      favicon.href = `${contact.img}`
      document.head.appendChild(favicon)
    }
  }, [contact])


  const [rows, setRows] = useState([])

  useEffect(() => {
    if (contact) {
      setTradeName(contact?.tradeName || '')
      document.title = contact?.name ? contact?.name + `(${contact?.tradeName ? contact?.tradeName : 'N/A'})` : 'Static Page'
      // console.log(contact)
    }
  }, [contact])
  const tableContainerRef = useRef();

  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const total = useMemo(() => {
    let total = 0
    if (rowSelectionModel) {
      rows?.filter((row) => {
        if (rowSelectionModel.map((el) => el?.id).includes(row.id)) {
          total += +row.total
        }
      })
    }
    return total
  }, [rowSelectionModel, rows])


  const [instancePDf, updatePDF] = usePDF({
    document: <Albaran items={[]} order={{}} contact={{}} />,
  })

  const balance = useMemo(() => {
    if (foundFactura) {
      return parseFloat(foundFactura?.paidValue)
        ? (
            (parseFloat(foundFactura?.deliveryPrice) ? parseFloat(foundFactura.deliveryPrice) : 0) +
            foundFactura.items
              ?.map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              ?.reduce((acc, curr) => acc + curr, 0)
              ?.toFixed(2) -
            (parseFloat(foundFactura?.paidValue)?.toFixed(2) || 0)
          )?.toFixed(2)
        : (
            (parseFloat(foundFactura?.deliveryPrice) ? parseFloat(foundFactura.deliveryPrice) : 0) +
            foundFactura.items
              ?.map((items) => (items.marketPriceCP != '' ? parseFloat(items.marketPriceCP * (items.quantity || 1)) : 0))
              ?.reduce((acc, curr) => acc + curr, 0)
          )?.toFixed(2)
    }
  }, [foundFactura])
  const [creationDate, setCreationDate] = useState('')
  const [creationTime, setCreationTime] = useState('')
  const [items, setItems] = useState([])

  useEffect(() => {
    if (foundFactura) {
      document.title = `Order ${foundFactura._id?.slice(-6)} ${foundFactura.tradeName || foundFactura.userAcc?.tradeName || 'No TradeName'}`
      
      setItems(foundFactura.items)

      setCreationDate(dayjs(foundFactura?.fetcha).format('DD/MM/YYYY'))
      setCreationTime(dayjs(foundFactura?.fetcha).format('HH:mm'))
    }
  }, [foundFactura])

  useEffect(() => {
    if (ordersForDetail) {
      setValuesForDetail(rowValue)
    }
  }, [ordersForDetail])
  

  return isLoading ? (
    <Container>
      <CircularProgress />
    </Container>
  ) : isError ? (
    <Container sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography variant='h1' sx={{ textAlign: 'center', fontFamily: 'Inter' }} color={'error'}>
        Error: check u user id is correct or not. User id: {location.pathname.split('/').pop()}
      </Typography>
    </Container>
  ) : (
    <div className='staticPage'>

      <StyledBox defaultVisible={true} sx={{ width: 'max-content', minWidth: '300px', maxWidth: '100%' }}>
          <div className='orderInfo__box__item'>
            <p>
              Number: <span>{foundFactura?.num ? foundFactura.num : foundFactura?._id ? foundFactura?._id.slice(-6) : ''}</span>
            </p>
          </div>

          <div className='orderInfo__box__item'>
            <p>
              Cuenta:{' '}
              <span>
                {foundFactura?.cuenta}
              </span>
            </p>
          </div> 
          <div className='orderInfo__box__item'>
            <p>
              Debt: <span style={{ color: (foundFactura.total - foundFactura.estada) <= 0 ? 'green' : 'red', textAlign: 'right' }}>{(foundFactura.total - foundFactura.estada).toFixed(2)}€</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Total: <span>{(parseFloat(foundFactura?.total) ? parseFloat(foundFactura?.total).toFixed(2) : '0.00') + '€'}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              Paid: <span>{(parseFloat(foundFactura?.payValue) ? parseFloat(foundFactura?.payValue).toFixed(2) : '0.00') + '€'}</span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              CREATION:{' '}
              <span>
                {creationDate} {creationTime}
              </span>
            </p>
          </div>
          <div className='orderInfo__box__item'>
            <p>
              TOTAL ITEMS: <span>{items ? items.length : 0}</span>
            </p>
          </div>
          <div className='orderInfo__box__item' style={{ backgroundColor: foundFactura?.status?.color, color: 'white', padding: '0px 5px', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p>
              Status: <span>{foundFactura?.status ? foundFactura.status?.name : 'No Status'}</span>
            </p>
          </div>
        </StyledBox>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row', gap: '10px' }}>
        <Button
          sx={{
            m: 2,
            width: mobileView ? '100%' : '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Archivo Black',
            background: '#5FC56E',
            borderRadius: '8px'
          }}
          variant='contained'
          onClick={() => { 
            navigate(`/category/${tradeName}`)
          }}
          // color='success'
        >
          {t('StaticPage.btn.newOrder')}
        </Button>
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row', gap: '10px' }}>
        <Button
          sx={{
            m: 2,
            width: mobileView ? '100%' : '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Archivo Black',
            background: '#5FC56E',
            borderRadius: '8px'
          }}
          variant='contained'
          disabled={isLoadingFacturas}
          onClick={() => { 
            if (idParams) {
              setValuesForPdf({ ...foundFactura, id: foundFactura._id, fetcha: new Date(foundFactura.fetcha) })
            } else {
              alert('Error in load facturas')
            }
          }}
          // color='success'
        >
          DownLoad facturas
        </Button>
      </Box>


      <Modal open={openDummy} onClose={() => setOpenDummy(false)}>
        {instancePDf.loading && instanceFacturaDetail.loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fafafa',
              width: 150,
              height: 200,
              padding: 2,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instancePDf.url}
              download={pdfName}
            >
              Download
            </Link>
            {detailLoading ? (
              <CircularProgress />
            ) : (
              <Link
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: 600,
                  border: '1px solid black',
                  padding: 1,
                  borderRadius: 2,
                  width: '100%',
                  textAlign: 'center',
                }}
                href={instanceFacturaDetail.url}
                download={pdfName}
              >
                Download Detalizada
              </Link>
            )}

            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instancePDf.url}
              target='_blank'
              rel='noreferrer noopener'
            >
              Open
            </Link>
            {detailLoading ? (
              <CircularProgress />
            ) : (
              <Link
                sx={{
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: 'black',
                  fontWeight: 600,
                  border: '1px solid black',
                  padding: 1,
                  borderRadius: 2,
                  width: '100%',
                  textAlign: 'center',
                }}
                href={instanceFacturaDetail.url}
                target='_blank'
                rel='noreferrer noopener'
              >
                Open Detalizada
              </Link>
            )}
          </Box>
        )}
      </Modal>

    </div>
  )
}