import React, { useState, useEffect } from 'react'
import './ContentCardCounter.scss'
import inCartIcon from '../../assets/images/Car-Nav.png'
import { useDispatch, useSelector } from 'react-redux'
import { setAllItemsCart } from '../../../../store/slices/allItemsCart'
import { setCartElemCurrentUnit, setCartElemQuantityValue } from '../../../../store/slices/allItemsCart'
import { setCartModal, setUiFavoriteModal } from '../../../../store/slices/contextUI'
import useFindItemByUnit from '../../../../hooks/useFindItemByUnit'
import useFindInCart from '../../../../hooks/useFindInCart'

const ContentCardCounter = ({ item }) => {
  const [cardUnits, setCardUnits] = useState(item?.unit == undefined ? ['none'] : item?.unit?.split(', '))
  const dispatch = useDispatch()
  const allItemsCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const grammMode = useSelector((state) => state.contextUI.grammMode)
  
  
  const inCart = useFindInCart(item)
  
  
  const [showQuantity, setShowQuantity] = useState()
  const [itemSettings, setItemSettings] = useState({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})

  
  useEffect(() => {
    setItemSettings({quantity: parseFloat(inCart?.quantity) || 0, unit: inCart?.currentUnit || cardUnits[0]})
    setShowQuantity(grammMode ? (parseFloat((inCart?.quantity ? inCart.quantity*1000 : 0))) : (parseFloat((inCart?.quantity ? inCart.quantity : 0))))
  }, [inCart, grammMode])
  
  const handleChangeUnit = (unit) => {
    setItemSettings({...itemSettings, unit: unit})
    dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: unit }))
  }
  
  const handleSetCount = (value) => {
    setShowQuantity((parseFloat(value)).toFixed(0))
    if (grammMode) {
      setItemSettings({...itemSettings, quantity: parseFloat(value)/1000})
      !inCart && (parseFloat(value)/1000) > 0 && dispatch(setAllItemsCart(item))
      inCart && (parseFloat(value)/1000) <= 0 && dispatch(setAllItemsCart(item))
      dispatch(setCartElemQuantityValue({ value: parseFloat(value)/1000, elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      setItemSettings({...itemSettings, quantity: parseFloat(value)})
      !inCart && parseFloat(value) > 0 && dispatch(setAllItemsCart(item))
      inCart && parseFloat(value) <= 0 && dispatch(setAllItemsCart(item))
      dispatch(setCartElemQuantityValue({ value: parseFloat(value), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }}

  const handleDecrement = () => {
    if (grammMode) {
      if (itemSettings.quantity > 0) {
        setItemSettings({...itemSettings, quantity: parseFloat((itemSettings.quantity - 0.1).toFixed(1))})
      }
      if (itemSettings.quantity <= 0.1) {
        setItemSettings({quantity: 0, unit: item?.unit})
        inCart && dispatch(setAllItemsCart(item))
      }
      dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity-0.1).toFixed(1)), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      if (itemSettings.quantity > 0) {
        setItemSettings({...itemSettings, quantity: parseFloat((itemSettings.quantity - 1).toFixed(1))})
      }
      if (itemSettings.quantity <= 1) {
        setItemSettings({quantity: 0, unit: item?.unit})
        inCart && dispatch(setAllItemsCart(item))
      }
      dispatch(setCartElemQuantityValue({ value: parseFloat(itemSettings.quantity-1), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }
  }

  const handleIncrement = () => {
    if (grammMode) {
      if (!inCart) {
        dispatch(setAllItemsCart(item))
        dispatch(setCartElemQuantityValue({ value: parseFloat(0.1.toFixed(1)), elem: item }))
      }
      inCart && dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity+0.1).toFixed(1)), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    } else {
      !inCart && dispatch(setAllItemsCart(item))
      inCart && dispatch(setCartElemQuantityValue({ value: parseFloat((itemSettings.quantity+1).toFixed(1)), elem: item }))
      dispatch(setCartElemCurrentUnit({ elem: { sku: item?.sku}, value: itemSettings.unit }))
    }
  }
  
  const totalPrice = allItemsCart.map((el) => el?.spoPrice ? el?.spoPrice : el.marketPriceCP * (el.quantity*useFindItemByUnit(item, el.currentUnit))).reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  const IVA = allItemsCart.map((el) => (el?.spoPrice ? el?.spoPrice * el.quantity * useFindItemByUnit(item, el.currentUnit) / (1 + el.IVACP/100) : el.marketPriceCP * el.quantity * useFindItemByUnit(el, el.currentUnit) / (1 + el.IVACP/100)))
  .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
  
  
  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  
  return (
    <div className='ContentCardCounter' key={item?.sku+'ContentCardCounter'}>
      {cardUnits.length>1 && <div className='ContentCardCounter__units'>
        {cardUnits.map((unit, index) => (
          <div
            key={'ContentCardCounter__units__item-'+index+'_'+item?.sku}
            className='ContentCardCounter__units__item'
            style={{
              background: itemSettings.unit === unit ? '#fff' : '#5fc56e',
              color: itemSettings.unit === unit ? '#5fc56e' : '#fff'
            }}  
            onClick={() => {handleChangeUnit(unit)}}
          >
            {unit == 'kg' & grammMode ? 'g' : unit} 
          </div>
        ))}
      </div>}
      <div className='ContentCardCounter__quantity'>
        <div className='ContentCardCounter__quantity__counter'>
          <div 
            onClick={() => {handleDecrement()}}
            className='ContentCardCounter__quantity__counter__change'
          >-</div>
          <div className='ContentCardCounter__quantity__counter__input'>
            <input 
              type="number" 
              value={showQuantity}
              onKeyDown={(event) => {
                if (event.key==='.')
                  {event.preventDefault()}
                }}
              onInput={(event) => {
                event.target.value = event.target.value.replace(/[^0-9]*/g,'')
              }}
              onChange={(e) => {handleSetCount(e.target.value)}}
              onBlur={(e) => {e.target.value == '' ? handleSetCount(0) : handleSetCount(e.target.value)}}
            />
          </div>
          <div
            onClick={() => {handleIncrement()}}
            className='ContentCardCounter__quantity__counter__change'
          >+</div>
      </div>
        </div>
        {inCart &&<div 
          className='ContentCardCounter__quantity__addCart'
          onClick={() => {
            dispatch(setCartModal(true))
            dispatch(setUiFavoriteModal(false))
          }}
        >
         <img src={inCartIcon} alt="" />
         {ivaSwitch ? totalPrice.toFixed(2) : IVA.toFixed(2)}€
        </div>}
    </div>
  )
}

export default ContentCardCounter