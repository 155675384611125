import React, { useState } from 'react'
import './OwnerPage.scss'
import { ReactComponent as ShareIcon } from '../components/assets/images/OwnerPage/shareIcon.svg'
import { ReactComponent as TurnoverIcon } from '../components/assets/images/OwnerPage/turnoverIcon.svg'
import { ReactComponent as ProfitIcon } from '../components/assets/images/OwnerPage/profitIcon.svg'
import { ReactComponent as DebtIcon } from '../components/assets/images/OwnerPage/debtIcon.svg'

const OwnerPage = () => {
  const ownerData = {
    img: 'https://i.imgur.com/1Hnzy5V.png',
    name: 'Pol Ballart',
    phone: '34 664 190 440',
    codes: [
      {
        code: '1KNE',
        idT: 'a2984c',
        name: 'Grup Mesamunt SL (1KNE)',
        turnover: 5658.79,
        profit: 2459.97,
        debt: 631.73,
        orders: 23,
      },
      {
        code: '2KNE',
        idT: '172dd5',
        name: 'Grup Benamunt SL (2KNE)',
        turnover: 8989.29,
        profit: 3821.16,
        debt: 594.77,
        orders: 21,
      },
      {
        code: '3KNE',
        idT: '7c58a0',
        name: 'Central kitsune 2016 SL (3KNE)',
        turnover: 1444.14,
        profit: 484.12,
        debt: 0,
        orders: 6,
      },
      {
        code: '4KNE',
        idT: '172dd6',
        name: 'Central kitsune 2016 SL (4KNE)',
        turnover: 0,
        profit: 0,
        debt: 0,
        orders: 0,
      },
      {
        code: 'NORS',
        idT: '172d63',
        name: 'Japonology SL',
        turnover: 3074.88,
        profit: 1366.15,
        debt: 953.16,
        orders: 22,
      },
      {
        code: 'MPSG',
        idT: '3177fe',
        name: 'BASEF & AMMA SL (MPSG)',
        turnover: 785.96,
        profit: 258.53,
        debt: 240.84,
        orders: 12,
      },
      {
        code: 'CRN',
        idT: 'cb79f5',
        name: 'Restaurante Lounge Bar La Estrella SL (CRN)',
        turnover: 927.20,
        profit: 310.27,
        debt: 927.20,
        orders: 5,
      },
      {
        code: 'KFK',
        idT: '6ef784',
        name: 'Huberts & Huberts SL',
        turnover: 1296.85 ,
        profit: 500.30,
        debt: 1296.85,
        orders: 7,
      },
      {
        code: 'KEAN',
        idT: '115c7b',
        name: 'Keanu Gastro SL',
        turnover: 0,
        profit: 0,
        debt: 0,
        orders: 0,
      },
      {
        code: 'SPDM',
        idT: 'f028a9',
        name: 'Espacio Popular Mexicano SL (SPDM)',
        turnover: 4019.82,
        profit: 2207.88,
        debt: 1811.94,
        orders: 28,
      },

    ]
  }

  const [selectedCodes, setSelectedCodes] = useState([])

  const headerColumnTable = [
    { title: '.', width: '37px'},
    { title: 'number', width: '80px'},
    { title: 'code', width: '50px'},
    { title: 'name', width: '280px'},
    { title: 'turnover', width: '100px'},
    { title: 'paid', width: '100px'},
    { title: 'debt', width: '100px'},
    { title: 'orders', width: '52px'},
    { title: 'avg order ', width: '80px'},
    { title: 'comission total', width: '120px'},
    { title: 'comission paid', width: '120px'},
  ]

  const tabl = [
    {
      name: 'Monday',
      color: '#407afe',
      code: ['CDAB', 'TPL', '2CHL', 'ARKO', 'CVC', 'CFLR', 'HMON', 'HPSF', 'HPSF', 'HPSF',],
      order: [
        {code: 'f2bb77', active: true, link: 'https://tpoapp.com/account/order/678faaed46dc790cb5f2bb77' },
        {code: 'f2a04d', active: true, link: 'https://tpoapp.com/account/order/678ed55346dc790cb5f2a04d'},
        {code: '881867', active: false },
        {code: '726777', active: false },
        {code: '87237', active: false },
        {code: '5126688', active: false },
        {code: '512675', active: false },
        {code: '612267', active: false },
        {code: '612267', active: false },
        {code: '612267', active: false },
      ]
    },
    {
      name: 'Truesday',
      color: '#fe725e',
      code: ['CDAB', 'TPL', '2CHL', 'ARKO', 'CVC', 'CFLR', 'HMON',],
      order: [
        {code: 'f2bb77', active: true, link: 'https://tpoapp.com/account/order/678faaed46dc790cb5f2bb77' },
        {code: 'f2a04d', active: true, link: 'https://tpoapp.com/account/order/678ed55346dc790cb5f2a04d'},
        {code: '881867', active: false },
        {code: '726777', active: false },
        {code: '87237', active: false },
        {code: '5126688', active: false },
        {code: '512675', active: false },
      ]
    },
    {
      name: 'Wednesday',
      color: '#d8d8d8',
      code: [],
      order: []
    },
    {
      name: 'Thursday',
      color: '#fec727',
      code: ['CDAB', 'TPL', '2CHL', 'ARKO',],
      order: [
        {code: 'f2bb77', active: true, link: 'https://tpoapp.com/account/order/678faaed46dc790cb5f2bb77' },
        {code: 'f2a04d', active: true, link: 'https://tpoapp.com/account/order/678ed55346dc790cb5f2a04d'},
        {code: '881867', active: false },
        {code: '726777', active: false },
      ]
    },
    {
      name: 'Friday',
      color: '#d17c19',
      code: ['CDAB', 'TPL', '2CHL',],
      order: [
        {code: 'f2bb77', active: true, link: 'https://tpoapp.com/account/order/678faaed46dc790cb5f2bb77' },
        {code: 'f2a04d', active: true, link: 'https://tpoapp.com/account/order/678ed55346dc790cb5f2a04d'},
        {code: '881867', active: false },
      ]
    },
    {
      name: 'Monday',
      color: '#6d26e1',
      code: ['CDAB', 'TPL', '2CHL', 'ARKO', 'CVC',],
      order: [
        {code: 'f2bb77', active: true, link: 'https://tpoapp.com/account/order/678faaed46dc790cb5f2bb77' },
        {code: 'f2a04d', active: true, link: 'https://tpoapp.com/account/order/678ed55346dc790cb5f2a04d'},
        {code: '881867', active: false },
        {code: '726777', active: false },
      ]
    },
    {
      name: 'Sunday',
      color: '#d8d8d8',
      code: [],
      order: []
    },
  ]

  return (
    <div className='OwnerPage'>
      <div className='OwnerPage__row'>
        <section>
          <div className='OwnerPage__imageBox'>
            <img src={ownerData.img} alt="" />
          </div>
        </section>
        <section style={{width: '100%', height: '148px'}}>
          <div className='OwnerPage__infoUser'>
            <h2>{ownerData.name}</h2>
            <p>{ownerData.phone}</p>
          </div>
          <div className='OwnerPage__infoStats'>
            <img src="https://i.imgur.com/Ez5Y6zk.png" alt="" />
          </div>
        </section>
      </div>
      <div className='OwnerPage__row'>
        <section className='OwnerPage__infoBar'>
          <div className='OwnerPage__infoBar__select'>
            Selected: {selectedCodes.length}
          </div>
          <div className='OwnerPage__infoBar__turnover'>
            <TurnoverIcon width={18} height={18} />
            <span>
              Turnover: {selectedCodes.reduce((acc, item) => acc + item.turnover, 0).toFixed(2)} €
            </span>
          </div>
          <div className='OwnerPage__infoBar__profit'>
            <ProfitIcon width={18} height={18} />
            <span>
              Paid: {selectedCodes.reduce((acc, item) => acc + item.turnover-item.debt, 0).toFixed(2)} €
            </span>
          </div>
          <div className='OwnerPage__infoBar__debt'>
            <DebtIcon width={18} height={18} />
            <span>
              Debt: {selectedCodes.reduce((acc, item) => acc + item.debt, 0).toFixed(2)} €
            </span>
          </div>
        </section>
      </div>
      <div className='OwnerPage__row'>
        <section className='OwnerPage__table' style={{ padding: '5px 15px'}}>
          <div className='OwnerPage__table__row' style={{height: '20px', marginBottom: '0px'}}>
            {headerColumnTable.map((item, index) => (<div key={index} style={{width: item.width}} className='OwnerPage__table__row__headerColumn'>{item.title}</div>))}
          </div>
          {ownerData.codes.map((item, index) => (
            <div key={index} className='OwnerPage__table__row'>
              <input type="checkbox" onClick={() => {
                if (selectedCodes.map((el) => el.code).includes(item.code)) {
                  setSelectedCodes(selectedCodes.filter((el) => el.code !== item.code))
                } else {
                  setSelectedCodes([...selectedCodes, item])
                }
              }} checked={selectedCodes.map((el) => el.code).includes(item.code)}/>
              <div className='OwnerPage__table__row__background'>
                <button onClick={() => {window.open(`https://tpoapp.com/account/${item.code}`,'_blank')}}>
                  {item.idT}
                  <ShareIcon width={20} height={20}/>
                </button>
                <div style={{width: '50px'}}>
                  {item.code}
                </div>
                <div style={{width: '280px'}}>
                  {item.name}
                </div>
                <div style={{width: '100px'}}>
                  {item.turnover} €
                </div>
                <div style={{width: '100px'}}>
                  {(item.turnover-item.debt).toFixed(2)} €
                </div>
                <div style={{width: '100px'}}>
                  {item.debt} €
                </div>
                <div style={{width: '52px'}}>
                  {item.orders}
                </div>
                <div style={{width: '80px'}}>
                  {item.orders !== 0 ? (item.turnover/item.orders).toFixed(2) : 0} €
                </div>
                <div style={{width: '120px'}}>
                  {(item.turnover/100*5).toFixed(2)} €
                </div>
                <div style={{width: '120px'}}>
                  {((item.turnover-item.debt)/100*5).toFixed(2)} €
                </div>
              </div>
            </div>
          ))}
        </section>
      </div>
      <div className='OwnerPage__row' style={{flexWrap: 'wrap'}}>
        {tabl.map((item, index) => (
          <div className='OwnerPage__dayCard' key={index}>
            <div className='OwnerPage__dayCard__title' style={{background: item.color}}>{item.name}</div>   
            <div className='OwnerPage__dayCard__dataShow'>
              {item.code.length > 0 &&
              <div className='OwnerPage__dayCard__dataShow__column'>
                <div className='OwnerPage__dayCard__dataShow__column__title'>Code</div>
                {item.code.map((code, i) => (<div key={i}>{code}</div>))}
              </div>}
              {item.order.length > 0 &&
              <div className='OwnerPage__dayCard__dataShow__column'>
                <div className='OwnerPage__dayCard__dataShow__column__title'>Order</div>
                {item.order.map((order, i) => (
                  <div 
                    key={i} 
                    style={{ color: order.active == true ? 'green' : '#252525'}}
                    onClick={() => {window.open(order.link,'_blank')}}
                  >{order.code}</div>))}
              </div>}
              {item.code.length == 0 && item.order.length == 0 && <div className='OwnerPage__dayCard__dataShow__notWork'>Not Work</div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default OwnerPage