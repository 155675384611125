import React, { startTransition, useEffect, useMemo, useRef, useState } from 'react'
import './StaticPage.scss'
import { mainApi } from '../../../store/services/mainApi'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Link,
  Modal,
  Select,
  Typography,
  useMediaQuery,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Autocomplete,
  TextField,
  Badge,
  styled,
} from '@mui/material'
import { DataGrid, GridPagination, GridToolbar, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid'
import { Dashboard } from './Dashboard/Dashboard'
import Albaran from '../Albaran/Albaran'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
import { usePDF } from '@react-pdf/renderer'
import FastOrder from './FastOrder/FastOrder'
import { StaticOrder } from '../StaticOrder/StaticOrder'
import { ReactComponent as ArrowDown } from '../../components/assets/images/galochkaStatic.svg'
import { useTranslation } from 'react-i18next'
import { startOfWeek, endOfWeek, eachWeekOfInterval, startOfMonth, endOfMonth } from 'date-fns'

import PDS from '../PrintDocuments/Facturas/PDS'
import dayjs from 'dayjs'
import { useLocation, useNavigate, useParams } from 'react-router'
import i18n from '../../../Localize';
import linksData from '../../../constants/linksData'
import { SwipeLeft } from '@mui/icons-material'
import DataGridTable from '../StaticOrder/DataGridTable/DataGridTable'
import searchActive from '../../components/assets/images/Search_active.png'

export default function StaticPage() {
  const [tradeName, setTradeName] = useState('')
  const navigate = useNavigate()
  const [orderForPDF, setOrderForPDF] = useState({})
  const [orderIdforPDF, setOrderIdforPDF] = useState('')

  //localization

  const { t } = useTranslation()
  const mobileView = useMediaQuery('(max-width: 798px)')

  const [vpfOrders, setVpfOrders] = useState([])

  const [viewOrder, setViewOrder] = useState(false)
  const [viewOrderId, setViewOrderId] = useState('')

  // // table switcher
  const urlParams = new URLSearchParams(window.location.search);
  const facturasQuerry = urlParams.get('facturas');
  const location = useLocation()
  const [tableType, setTableType] = useState(facturasQuerry == 1 ? false : location.pathname.split('/').includes('facturas') ? false : true)
  
  const tableRef = useRef(null);

  
  // //clone modal
  // const [cloneModal, setCloneModal] = useState(false)
  const tradeNameParams = useParams().tradeName ;
  const {
    data: contact,
    isLoading,
    isError,
  } = mainApi.useGetBuisnessContactByTradeNameQuery(tradeNameParams, {
    skip: !tradeNameParams,
  })
  
  //facturas table
  
  const [instanceFactura, updateFactura] = usePDF({
    document: (
      <PDS
        items={[]}
        order={{}}
        adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
        contact={{}}
        name={'Sergey Fedorenko'}
        value={'X7031676R'}
        date={dayjs()}
        number={''}
        numberInYear={''}
        IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
      />
    ),
  })

  const {
    data: facturas,
    isLoading: isLoadingFacturas,
    isFetching: isFetchingFacturas,
  } = mainApi.useGetFacturasByClientQuery(
    { tradeName: contact?.tradeName },
    {
      skip: !contact,
    },
  )
  useEffect(() => {
      if (facturasQuerry == 1 && !isLoading) {
        setTimeout(() => {
          tableRef.current?.scrollIntoView();
        }, 200)
      }
      if (location.pathname.split('/').includes('facturas')  && !isLoading) {
        setTimeout(() => {
          tableRef.current?.scrollIntoView();
        }, 200)
      }
  }, [tableType, isLoadingFacturas]);
  
  const [openDummy, setOpenDummy] = useState(false)

  const [pdfName, setPdfName] = useState('')

  const setValuesForPdf = (value) => {
    console.log(value, 'value')
    setOpenDummy(true)
    setPdfName(value.cliente + '/' + value.fetcha.toISOString() + '.pdf')
    if (value.cuenta === 'Sergey Fedorenko') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'JOSEP PLA 29 1 - 1, Barcelona, 08019'}
          contact={value.contact}
          name={'Sergey Fedorenko'}
          value={'X7031676R'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES79 0081 0168 9600 0196 1601'}
        />,
      )
    } else if (value.cuenta === 'Claudia Cires') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 4, Barcelona, 08020'}
          contact={value.contact}
          name={'Claudia Cires'}
          value={'Y4155866X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES70 0081 0168 9300 0178 7989'}
        />,
      )
    } else if (value.cuenta === 'Moldovenesque SL') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Rambla de Prim 166 local 3, Barcelona, 08020'}
          contact={value.contact}
          name={'Moldovenesque SL'}
          value={'B67505446'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES56 0081 0126 0000 0164 3675'}
        />,
      )
    } else if (value.cuenta === 'Alexandru Clim') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          contact={value.contact}
          adress={'Carrer del Maresme, 274, Sant Martí, 08020 Barcelona, 4-1'}
          name={'Alexandru Clim'}
          value={'Z0107137P'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'ES51 0081 0168 9100 0198 9510'}
        />,
      )
    } else if (value.cuenta === 'Roman Horokhov') {
      updateFactura(
        <PDS
          items={value.items}
          order={value.order}
          adress={'Passeig Calvell 27, Barcelona, 08005'}
          contact={value.contact}
          name={'Roman Horokhov (VPF)'}
          value={'Y3467154X'}
          date={dayjs(value.fetcha)}
          number={value.number}
          numberInYear={value.numberInYear}
          IBAN={'IBAN ES13 0081 5224 4100 0180 0081'}
        />,
      )
    }
  }

  const [facturasRowData, setFacturasRowData] = useState([])

  useEffect(() => {
    if (facturas && !isLoadingFacturas && !isFetchingFacturas) {
      setFacturasRowData(facturas?.map((factura) => ({ ...factura, id: factura._id, fetcha: new Date(factura.fetcha) })))
    }
  }, [facturas])

  const facturasColumns = [
    {
      field: 'fetcha',
      headerName: 'Fecha',
      width:  mobileView ? 100 : 150,
      type: 'date',
      renderCell: (params) => (
        <div>{dayjs(params.value).format('DD/MM/YYYY')}</div>
      )
    },
    { 
      field: 'num',
      headerName: 'Num',
      width:  mobileView ? 100 : 150,
      editable: true
    },
    {
      field: 'status',
      headerName: 'Status',
      width: mobileView ? 100 : 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor: params.value?.color,
              borderRadius: '10px',
              padding: '5px 0px',
              color: '#fff',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {params.value?.name}
          </div>
        )
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: mobileView ? 160 : 160,
      renderCell: (params) => (
        <Box>
          <Button
            onClick={(e) => {
              e.stopPropagation()
              setValuesForPdf(params.row)
            }}
            size='small'
            variant='contained'
            sx={{ m: 1 }}
          >
            Download
          </Button>
        </Box>
      ),
    },
    { 
      field: 'total',
      headerName: 'Total',
      type: 'number',
      width: 100,
      renderCell: (params) => `${params.value} €`
    },
    {
      field: 'payValue',
      headerName: 'Paid',
      width: mobileView ? 100 : 100,
      renderCell: (params) => {
        return params?.value ? parseFloat(params.value)?.toFixed(2) + ' €' : '0 €'
      },
    },
    { 
      field: 'cliente',
      headerName: 'Cliente',
      width: 300,
      renderCell: (params) => {
        return (
          <div
            title={params.value}
            style={{
              width: '300px',
              display: 'flex',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap', 
            }}
          >
            {params.value}
          </div>
        )
      },
    },
  ]

  // useEffect(() => {
  //   console.log(facturas)
  // }, [facturas])

  const { data: orders, isLoading: isLoadingOrders } = mainApi.useGetOrdersByTradeNameQuery(tradeName, {
    skip: !tradeName,
  })
  useEffect(() => {
    if (tradeName === null || tradeName === undefined) {
      console.log('tradeName is null or undefined.')
    } else if (tradeName === 'VPF') {
      fetch('https://tpomobi.shop/getOrdersAdmin?tradeName=VPF', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json === null || json === undefined) {
            console.log('json is null or undefined.')
          } else {
            setVpfOrders(json)
          }
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [tradeName])

  useEffect(() => {
    if (contact) {
      // document.title = `${contact.name}`
      const favicon = document.querySelector("link[rel*='icon']") || document.createElement('link')
      favicon.rel = 'icon'
      favicon.href = `${contact.img}`
      document.head.appendChild(favicon)
    }
  }, [contact])

  // useEffect(() => {
  //   console.log(vpfOrders, 'vpfOrders')
  // }, [vpfOrders])

  const [rows, setRows] = useState([])

  useEffect(() => {
    if (contact) {
      setTradeName(contact?.tradeName || '')
      document.title = contact?.name ? contact?.name + `(${contact?.tradeName ? contact?.tradeName : 'N/A'})` : 'Static Page'
      // console.log(contact)
    }
  }, [contact])
  const tableContainerRef = useRef();



  useEffect(() => {
    // if (tableType) {
    if (tradeName === 'VPF') {
      if (vpfOrders && vpfOrders.length > 0) {
        const updatedRows = vpfOrders.map((item) => {
          return {
            ...item,
            id: item._id,
            idT: item.idTucomida ? item.idTucomida : item._id ? item._id.slice(-6) : 'N/A',
            date: new Date(item.date),
            total: item?.items ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) : 0,
            balance: item?.paidValue
              ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) - item?.paidValue
              : item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0),
          }
        })
        setRows(updatedRows)
      }
    } else {
      if (orders) {
        const updatedRows = orders.map((item) => {
          return {
            ...item,
            id: item._id,
            idT: item.idTucomida ? item.idTucomida : item._id ? item._id.slice(-6) : 'N/A',
            date: new Date(item.date),
            total: item?.items ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) : 0,
            balance: item?.paidValue
              ? item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0) - item?.paidValue
              : item?.items.reduce((acc, curr) => acc + curr.marketPriceCP * (curr.quantity || 0), 0),
          }
        })
        setRows(updatedRows)
      }
      // }
    }
  }, [orders, tradeName, vpfOrders])

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 10,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  const [columns, setColumns] = useState([
    {
      field: 'deliveryDate',
      headerName: t('StaticPage.tableHeaders.date'),

      width: mobileView ? 100 : 150, // Set a specific width for mobile view

      renderCell: (params) => {
        return (
          <p style={{ width: '100%' }}>
            {params?.value ? params.value : new Date(params.value).toLocaleDateString()}
          </p>
        )
      },
    },
    {
      field: 'idT',
      headerName: t('StaticPage.tableHeaders.id'),

      width: mobileView ? 100 : 150, // Set a specific width for mobile view
      renderCell: (params) => {
        // console.log(params);
        return (
          <StyledBadge badgeContent={params.row.items.length} color="primary" sx={{ width: '95%'}}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                width: '100%',
                border: '1px solid white',
                display: 'flex',
                alignItems: 'center', 
                justifyContent: 'center',
                padding: '5px 5px',
                marginTop: '5px',
                textAlign: 'center',
                fontWeight: 'bold',
                borderRadius: '5px',
                cursor: 'pointer',
                textDecoration: 'underline',
                textUnderlineOffset: '3px',
                backgroundColor: '#1976D2',
                transition: 'all 0.5s ease',
                color: '#fff',

                '&:hover': {
                  backgroundColor: '#4fa0f0',
                  color: '#000',
                },
              }}
              onClick={(e) => {
                e.stopPropagation() // Предотвращаем всплытие события
                // Затем установите ID для модального окна, используя правильное свойство
                setViewOrderId(params.row._id) // или params.row.id.orderId, если id - это объект
                setViewOrder(true) // Открываем модальное окно
              }}
            >
              {params.value || 'N/A'}
            </Typography>
          </StyledBadge>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: mobileView ? 100 : 150,

      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor: params.value?.color,
              borderRadius: '10px',
              padding: '5px 0px',
              color: '#fff',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {params?.value?.name ? params.value.name : 'Nuevo pedido'}
          </div>
        )
      },
    },
    {
      field: 'id',
      headerName: t('StaticPage.tableHeaders.actions'),
      width: mobileView ? 160 : 160, // Set a specific width for mobile view
      renderCell: (params) => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            size='small'
            variant='contained'
            sx={{ m: 1 }}
            onClick={() => {
              setOrderIdforPDF(params.value)
              setPrintModal(true)
            }}
          >
            PDF
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            size='small'
            variant='contained'
            sx={{ m: 1, minWidth: 'fit-content' }}
            onClick={() => {
              console.log(params);
              // window.open(`order/${params.row.id}`, '_blank')
            }}
          >
            {t('StaticPage.tableRows.share')}
          </Button>
        </Box></div>
      ),
    },
    {
      field: 'total',
      headerName: t('StaticPage.tableHeaders.total'),

      width: mobileView ? 100 : 100, // Set a specific width for mobile view

      renderCell: (params) => {
        return <p style={{ width: '100%', textAlign: 'center' }}>{parseFloat(params?.value)?.toFixed(2) || 'N/A'}€</p>
      },
    },
    {
      field: 'paidValue',
      headerName: t('StaticPage.tableHeaders.paid'),
      width: mobileView ? 100 : 100, // Set a specific width for mobile view
      renderCell: (params) => {
        // Проверяем, что params.value не равно null или undefined, прежде чем вызывать toFixed
        const value = params?.value != null || params?.value != undefined? parseFloat(params?.value)?.toFixed(2) : 0.001
        return (
          <span style={{ fontFamily: 'Inter', width: '100%', textAlign: 'center',
            color: (parseFloat(params?.value ? params.value : 0))?.toFixed(2) == 0 ? 'black' :
            (parseFloat(params?.value ? params.value : 0))?.toFixed(2) !== (params?.row?.total ? params.row.total : params).toFixed(2) ? '#51dd51new URLSearchParamsnew URLSearchParams' : 'green' }} 
            // className='AccountScroll__items'
            >
            {value}€
          </span>
        )
      },
    },
    {
      field: 'balance',
      headerName: t('StaticPage.tableHeaders.balance'),

      width: 300, // Set a specific width for mobile view
      renderCell: (params) => {
        return (
          <span style={{ fontFamily: 'Inter', textAlign: 'center', width: '100%', 
            color: (parseFloat(params?.value ? params.value : 0))?.toFixed(2) == 0 ? 'green' :
            (parseFloat(params?.value ? params.value : 0))?.toFixed(2) !== (params?.row?.total ? params.row.total : params).toFixed(2) ? 'orange' : 'red' }} className='AccountScroll__items'>
            {parseFloat(params?.value)?.toFixed(2) || '0'}€
          </span>
        )
      },
    },
    // {
    //   field: 'idShare',
    //   headerName: t('StaticPage.tableHeaders.actions'),
    //   width: mobileView ? 120 : 120, // Set a specific width for mobile view
    //   renderCell: (params) => (
        
    //   ),
    // },
  ])

  // const [facturasColumns, setFacturasColumns] = useState([
  //   {
  //     field: 'id',
  //     headerName: 'ID',
  //     width: 100,
  //   },
  // ])

  //selection
  const [rowSelectionModel, setRowSelectionModel] = useState([])

  const total = useMemo(() => {
    let total = 0
    if (rowSelectionModel) {
      rows?.filter((row) => {
        if (rowSelectionModel.map((el) => el?.id).includes(row.id)) {
          total += +row.total
        }
      })
    }
    return total
  }, [rowSelectionModel, rows])

  //month filters
  const [filterValue, setFilterValue] = useState('')
  const [filterYearValue, setFilterYearValue] = useState('')
  const [searchValue, setSearchValue] = useState('')


  const handleFilterChange = (e) => {
    setFilterValue(e.target.value)
  }



  const filtredRows = useMemo(() => {
    const selectedMonth = filterValue || new Date().getMonth() + 1 // Если месяц не выбран, используем текущий
    const selectedYear = new Date().getFullYear() // Можно также добавить фильтр по году, если нужно=
    return rows
      ?.filter((row) => {
        if (!filterValue) return true
        try {
          if (row.deliveryDate !== undefined) {
            const date = row.deliveryDate?.split(',')[0];
            const month = parseInt(date?.split('.')[1]);
            // console.log(month);
            return month == filterValue
          } else {
            return false
          }
        } catch (error) {
          return false
        }
      })
      ?.filter((row) => {
        if (!filterYearValue) return true
        try {
          if (row.deliveryDate !== undefined) {
            const date = row.deliveryDate?.split(',')[0];
            const year = parseInt(date?.split('.')[2]);
            console.log(row);
            return year == filterYearValue
          } else {
            return false
          }
        } catch (error) {
          return false
        }
      })
      ?.filter((row) => {
        if (!searchValue) return true
        try {
          if (row.deliveryDate !== undefined) {
            return row.deliveryDate.toLowerCase().includes(searchValue.toLowerCase()) ||
              row.idT.toLowerCase().includes(searchValue.toLowerCase()) ||
              row.total.toString().includes(searchValue);
          } else {
            return false
          }
        } catch (error) {
          return false
        }
      })
      ?.filter((row) => {
        if (row?.status._id === '65ea14fef2ce5f8bdbb131ab' || row?.status._id === '660eb916a99b60113aedfdc0') {
          return false
        } else {
          return true
        }
      })
  }, [rows, filterValue, filterYearValue, searchValue])

  //Albaran de compras

  const [instancePDf, updatePDF] = usePDF({
    document: <Albaran items={[]} order={{}} contact={{}} />,
  })

  const [printModal, setPrintModal] = useState(false)

  const itemsForAlbaran = useMemo(() => {
    if (orderForPDF?.items) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      orderForPDF.items.forEach((item) => {
        const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

        if (!uniqueItemsMap[key]) {
          // Если элемент с таким ключом еще не существует, добавляем его в объект
          uniqueItemsMap[key] = {
            conceptio: item.name,
            sku: item.sku,
            quantity: item.quantity,
            price: item.marketPriceCP,
            IVA: item.IVA ? item.IVA : 0,
            tipo: item.currentUnit ? item.currentUnit : '',
            total: (item.marketPriceCP * item.quantity)?.toFixed(2),
          }
        } else {
          // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
          uniqueItemsMap[key].quantity += item.quantity
          uniqueItemsMap[key].total = (uniqueItemsMap[key].price * uniqueItemsMap[key].quantity)?.toFixed(2)
        }
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object.values(uniqueItemsMap)

      return uniqueItems
    } else {
      return []
    }
  }, [orderForPDF])

  useEffect(() => {
    if (orderForPDF) {
      updatePDF(<Albaran items={itemsForAlbaran} order={orderForPDF} contact={contact} />)
    }
  }, [orderForPDF, contact, itemsForAlbaran])

  useEffect(() => {
    if (orderIdforPDF) {
      setOrderForPDF(orders?.find((item) => item._id === orderIdforPDF))
    }
  }, [orderIdforPDF])

  const { 
    data: contacts, 
    isLoading: isLoadingContacts,
  } = mainApi.useGetContactClientByTradeNameQuery(tradeNameParams.replace(/[0-9]/g, ''), {
    skip: !tradeNameParams,
  })

  const [contactsList, setContactsList] = useState([])
  useEffect(() => {
    if (!isLoadingContacts) {
      startTransition(() => {
        setContactsList(contacts?.map((el) => ({ id: el._id, label: el.tradeName })));
      });
    }
  }, [contacts]);



  const isNY25 = useParams().tradeName == 'NY25' ? true : false

  useEffect(() => {
    if (isNY25) {
      navigate('/category/NY25')
    }
  }, [isNY25])

  const filterMonthList = [
    {value: '1', title: 'Jan',},
    {value: '2', title: 'Feb',},
    {value: '3', title: 'Mar',},
    {value: '4', title: 'Apr',},
    {value: '5', title: 'May',},
    {value: '6', title: 'Jun',},
    {value: '7', title: 'Jul',},
    {value: '8', title: 'Aug',},
    {value: '9', title: 'Sep',},
    {value: '10', title: 'Oct',},
    {value: '11', title: 'Nov',},
    {value: '12', title: 'Dec',},
  ]
  const filterYearList = [
    {value: '2025', title: '2025'},
    {value: '2024', title: '2024'},
  ]
  

  return isLoading ? (
    <Container>
      <CircularProgress />
    </Container>
  ) : isError ? (
    <Container sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Typography variant='h1' sx={{ textAlign: 'center', fontFamily: 'Inter' }} color={'error'}>
        Error: check u user id is correct or not. User id: {location.pathname.split('/').pop()}
      </Typography>
    </Container>
  ) : (
    <div className='staticPage'>
      <Box
        className='staticPage__header'
        sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row' }}
      >
        <Typography
          className='staticPage__header__title'
          variant='h3'
          sx={{ textAlign: 'left', fontFamily: 'Archivo Black', m: 2, color: '#176318', textTransform: 'uppercase' }}
        >
          {contact?.name}
        </Typography>
        <LanguageSwitcher />
      </Box>
      {mobileView ? (
        <Button
          // variant='contained'
          sx={{
            m: 2,
            width: mobileView ? '100%' : 'max-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Archivo Black',
          }}
          color='success'
          onClick={() => {
            const element = document.getElementsByClassName('table')[0]
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }}
        >
          Ver pedidos <ArrowDown fill='green' style={{ marginLeft: '5px' }} />
        </Button>
      ) : null}
      <Dashboard contact={contact} orders={filtredRows} scrollList={filtredRows} tableContainerRef={tableContainerRef}/>

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: mobileView ? 'column' : 'row', gap: '10px' }}>
        {/* <FastOrder contact={contact} tradeName={tradeName} /> */}
        <Button
          sx={{
            m: 2,
            width: mobileView ? '100%' : '250px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'Archivo Black',
            background: '#5FC56E',
            borderRadius: '8px'
          }}
          variant='contained'
          onClick={() => { 
            navigate(`/category/${tradeName}`)
          }}
          // color='success'
        >
          {t('StaticPage.btn.newOrder')}
        </Button>
        <Box sx={ {display: 'flex', flexDirection: 'column'}}>
          <div className='staticPage__filterOrders' style={{ borderRadius: '6px 6px 0px 0px', flexWrap: 'noWrap'}}>
            <button 
              onClick={() => {setFilterYearValue(''); setFilterValue('')}} 
              className={filterYearValue == '' && filterValue == '' ? 'active' : ''} 
              style={{padding: '0px 10px', }}
            >
              All
            </button>
            {filterYearList.map((el) => 
            <button 
              onClick={() => setFilterYearValue(el.value)} 
              className={filterYearValue == el.value ? 'active' : ''} 
              style={{padding: '0px 10px'}}
            >
              {el.title}
            </button>)}
          </div>
          <div className='staticPage__filterOrders' style={{ borderRadius: '0px 6px 6px 6px'}}>
            {filterMonthList.map((el) => 
            <button 
              onClick={() => setFilterValue(el.value)}
              className={filterValue == el.value ? 'active' : ''}
            >
              {el.title}
            </button>)}
          </div>
        </Box>
        <div className='staticPage__search'>
          <input value={searchValue} onChange={(e) => setSearchValue(e.target.value)}/>
          <img src={searchActive}/>
        </div>
      </Box>

        <DataGridTable
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel)
          }}
          rowSelectionModel={rowSelectionModel}
          ref={tableContainerRef}
          ordersRows={filtredRows}
          ordersColumns={columns}
          facturasRows={facturasRowData}
          facturasColumns={facturasColumns} 
          fontSize={mobileView ? 12 : 15}
        />
      <Modal open={printModal} onClose={() => setPrintModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 200,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            gap: 2,
            fontFamily: 'Montserrat',

            height: 150,
          }}
        >
          <Link
            sx={{
              textDecoration: 'none',
              color: 'black',
              fontFamily: 'Montserrat',
              fontSize: 18,
              border: '1px solid black',
              padding: 1,
              borderRadius: 2,
              cursor: 'pointer',
              width: '80%',
              textAlign: 'center',
            }}
            href={instancePDf.url}
            rel='noreferrer'
            target='_blank'
            // onClick={() => setViewOrder(true)}
          >
            Open
          </Link>
          <Link
            sx={{
              textDecoration: 'none',
              color: 'black',
              fontFamily: 'Inter',
              fontSize: 18,
              border: '1px solid black',
              padding: 1,
              borderRadius: 2,
              cursor: 'pointer',
              width: '80%',
              textAlign: 'center',
            }}
            href={instancePDf.url}
            download={`albaran_${orderForPDF?.idTucomida ? orderForPDF?.idTucomida : orderForPDF?._id ? orderForPDF?._id.slice(0, 8) : 'no_id'}.pdf`}
          >
            Download
          </Link>
        </Box>
      </Modal>
      <Modal open={viewOrder} onClose={() => setViewOrder(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: mobileView ? '300px' : '900px',
            height: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            padding: 2,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <StaticOrder orderId={viewOrderId} />
        </Box>
      </Modal>

      {rowSelectionModel && rowSelectionModel.length > 0 && (
        <Box
          sx={{
            position: 'fixed',
            top: '90%',
            left: '5%',
            width: 250,
            height: 35,
            bgcolor: 'background.paper',
            display: 'flex',
            padding: 2,
            borderRadius: 2,
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: 24,
            border: '1px solid black',
            gap: 1,
          }}
        >
          <Typography sx={{ fontFamily: 'Inter' }}>
            {rowSelectionModel.length} {t('StaticPage.modal.selected')}
          </Typography>
          <Typography sx={{ fontFamily: 'Inter' }}>Total: {total?.toFixed(2)}€</Typography>
        </Box>
      )}

      <Modal open={openDummy} onClose={() => setOpenDummy(false)}>
        {instancePDf.loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fafafa',
              width: 150,
              height: 200,
              padding: 2,
              borderRadius: 5,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 2,
            }}
          >
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instanceFactura.url}
              download={pdfName}
            >
              Download
            </Link>
            <Link
              sx={{
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'black',
                fontWeight: 600,
                border: '1px solid black',
                padding: 1,
                borderRadius: 2,
                width: '100%',
                textAlign: 'center',
              }}
              href={instanceFactura.url}
              target='_blank'
              rel='noreferrer noopener'
            >
              Open
            </Link>
          </Box>
        )}
      </Modal>
    </div>
  )
}
