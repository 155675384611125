import React, { useEffect, useState } from 'react'
import { mainApi } from '../../../store/services/mainApi'
import { useNavigate, useParams } from 'react-router'
import ModalUI from '../ModalUI/ModalUI'
import './FastTempleOrder.scss'
import { Box, Button, CircularProgress, TextField, Modal } from '@mui/material'
import useUpdatePrice from '../../../hooks/useUpdatePrice'
import SearchItem from './SearchItem/SearchItem'
import useReplaceAccent from '../../../hooks/useReplaceAccent'
import SearchListItem from './SearchItem/SearchListItem/SearchListItem'
import { PhoneInput } from 'react-international-phone'
import { PhoneNumberUtil } from 'google-libphonenumber';
import axios from 'axios'
import orderDataConst from '../../../constants/OrderData'
import useSendLog from '../../../hooks/useSendLog'
import { useDispatch, useSelector } from 'react-redux'
import { setIdLastOrder, setIsLogin } from '../../../store/slices/contextUI'
import { setUserAcc } from '../../../store/slices/accountData'
import FTOOrderModal from './FTOOrdersModal/FTOOrderModal'
import TemplEditModal from './TemplEditModal/TemplEditModal'
import { setItemsTemplate } from '../../../store/slices/templateOrder'


const FastTempleOrder = ({tradeNameProp='', filterOrder, sxBtn={}, textBtn='Click Me'}) => {
  const { data: allProductsData, isLoading: isAllProductsLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  });

  function getQueryParamValue(param) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param) === 'true';
  }

  const [isOpenModal, setIsOpenModal] = useState(getQueryParamValue('cloneModal') || false)
  const userData = useSelector(state => state.accountData.userAcc)
  const tradeNameParams = tradeNameProp !== '' ? tradeNameProp : useParams().tradeName
  console.log(tradeNameParams);
  const platformParams = useParams().platform

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const sendOrderLog = useSendLog('Send Order', { 
  });
  
  const [editModal, setEditModal] = useState(false);
  const [searchModal, setSearchModal] = useState(false);

  const [cloneOrder, setCloneOrder] = useState({});
  const [editedItems, setEditedItems] = useState([]);

  useEffect(() => {
    setEditedItems(cloneOrder?.items ? [...cloneOrder.items] : [])
  }, [cloneOrder])

  // useEffect(() => {
  //   console.log('edData'+[...editedItems]);
  // }, [editedItems])
  useEffect(() => {
    if (cloneOrder && cloneOrder?.items) {
      const updatePriceList = useUpdatePrice(cloneOrder?.items ? [...cloneOrder.items] : [], allProductsData, isAllProductsLoading)
      setEditedItems(updatePriceList);
      dispatch(setItemsTemplate(updatePriceList))
      
    console.log(cloneOrder?.items);
    }
  }, [cloneOrder])
  
  
  const [createUserPhone, { data: resultPhone, isSuccess: isSuccessPhone }] = mainApi.useAddClientPersonalPhonesMutation()
  const userPhone = localStorage.getItem('savedPhone') ? localStorage.getItem('savedPhone') : JSON.parse(localStorage.getItem('LoginData'))?.phoneLogin
  const [searchWord, setSearchWord] = useState('');
  const [searchData, setSearchData] = useState([]);
  
  const [orderData, setOrderData] = useState(orderDataConst)
  const date = new Date()
  const formattedDate = !(date.getHours()>0&&date.getHours()<9) ? new Date(date.setDate(date.getDate() + 1)).toISOString()?.split('T')[0] : new Date().toISOString()?.split('T')[0];
  useEffect(() => {
    if (!isAllProductsLoading) {
      const withAccetWord = useReplaceAccent(searchWord.toLocaleLowerCase());
      // setSearchData();
      const searchList = allProductsData?.filter((el) => useReplaceAccent(el.name.toLocaleLowerCase()).includes(withAccetWord));
      setSearchData(searchList);
    }
  }, [searchWord, isAllProductsLoading])

  const [handleOrder, setHandleOrder] = useState(false)
  const [sendOrderModal, setSendOrderModal] = useState(false)
  const localUserData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
  const [nameUser, setNameUser] = useState(localUserData?.name ? localUserData.name : '')
  const [description, setDescription] = useState(localUserData?.tradeNameAdress ? localUserData.tradeNameAdress : '')
  const [number, setNumber] = useState(localUserData?.phone ? localUserData.phone : '')
  const [tradeName, setTradeName] = useState(localUserData?.tradeNameCode ? localUserData.tradeNameCode : '')
  const phoneUtil = PhoneNumberUtil.getInstance();

  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  };

  const [eTime, setETime] = useState('PLM ☀️')
  const [eDate, setEDate] = useState(formattedDate)
  useEffect(() => {
    setOrderData((prev) => ({
      ...prev, deliveryDate: formattedDate
    }))
  }, [])

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };
  const isValid = isPhoneValid(number);
  useEffect(() => {
    if (tradeNameParams !== undefined) {
      const tradeId = async () => { 
        const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeNameParams}`)
        setDescription(responseLogin.data?.name)
        setTradeName(responseLogin.data?.tradeName)
      }
      tradeId()
    } 
  }, [tradeNameParams])
  const [createOrder, { data: result, isSuccess }] = mainApi.useCreateNewOrderBrowserMutation()

  const handleSendOrder = async () => {
    setHandleOrder(true)
    let informData =  {
      phone: number.replace('+',''),
      password: 'any',
    };

    let cart = editedItems
    await createOrder({ data: {...orderData,
      deliveryDate: formatDate(eDate)+', '+eTime,
      deliveryTime: eTime,
      address: description !== '' ? description : '',
      items: cart,
      paymentType: 'Credit Line',
      tradeName: tradeNameParams !== '' ? tradeNameParams : userData.tradeName !== '' ? userData.tradeName : '',
      userAcc: {
        ...userData,
        name: nameUser !== '' ? platformParams !== undefined ? `${nameUser} (${platformParams})`: nameUser  : '',
        phone: number !== '' ? number.replace('+', '') : '',
        tradeName: tradeNameParams !== '' ? tradeNameParams : userData.tradeName !== '' ? userData.tradeName : '',
      }
    } }).then(async (res) => {
      sendOrderLog()

      dispatch(setIdLastOrder(res.data.insertedId))
      setSendOrderModal(false)
      localStorage.setItem('savedName', nameUser)
      localStorage.setItem('savedPhone', number)
      localStorage.setItem('LoginData', JSON.stringify({
        phoneLogin: number.replace('+',''),
        passwordLogin: '121212',
      }))
      localStorage.setItem('savedAddress', description)
      localStorage.setItem('savedTradeName', tradeName)
      const responseLogin = axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
      console.log(responseLogin.data)
      
      
      if (responseLogin.data !== '') {
        createUserPhone({ phone: number.replace('+','') }).then((res) => {
          console.log(res.data)
        })
      }
      dispatch(setUserAcc(responseLogin.data))
      dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
      setTimeout(() => {
        setHandleOrder(false)
      }, 5000)
      if (tradeNameParams !== undefined) {
        navigate(`/orderComplited/${tradeNameParams}`)
      } else {
        navigate('/orderComplited')
      }
    }).catch((err) => {
      alert('Try again!!!')
    })
  }

  const handleClickRepetirPedido = useSendLog('Click on Repetir pedido', {});

  return (
    <div>
      <button
        style={sxBtn}
        onClick={() => {
          setIsOpenModal(true)
          handleClickRepetirPedido()
        }}
      >
        {textBtn}
      </button>

      {isOpenModal && 
        <FTOOrderModal 
          setIsOpenModal={setIsOpenModal}
          setCloneOrder={setCloneOrder}
          setEditModal={setEditModal}
          setSendOrderModal={setSendOrderModal}
          allProductsLoading={isAllProductsLoading}
          tradeNameParams={tradeNameParams}
          filterOrder={filterOrder}
        />
      }
      {editModal && 
        <TemplEditModal
          setEditModal={setEditModal}
          setIsOpenModal={setIsOpenModal}
          editedItems={editedItems}
          setEditedItems={setEditedItems}
          setSearchModal={setSearchModal}
          setSendOrderModal={setSendOrderModal}
        />
      }
      {searchModal && 
      <ModalUI setIsOpenModal={() => setSearchModal(false)} sx={{padding: '20px 10px'}}>
        <SearchItem searchWord={searchWord} setSearchWord={setSearchWord} sx={{marginTop: '10px'}}/>
        {searchData?.slice(0, 10)?.map((el, index) => 
            <SearchListItem 
              item={el} 
              key={el.sku+index}
              setEditedItems={setEditedItems} 
              editedItems={editedItems}
            />
          )
        }
      </ModalUI>}
      <Modal open={sendOrderModal} onClose={() => {
        if (!handleOrder) {setSendOrderModal(false)}
      }}>
        <Box 
        className='NewOrderPage__mainEditOrder__sendOrder__modal'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          border: '3px solid #5FC56E',
          fontFamily: 'Montserrat',
          fontSize: '17px',
          color: '#5FC56E',
          borderRadius: 6,
          gap: 2,
          p: 4,
        }}>
          <b style={{marginBottom: '10px'}}>Genial👍<br/>
          Ahora dinos tus  detalles para acabar el pedido:</b>
          <TextField
            type="text"
            label="Tu nombre"     
            value={nameUser}
            onChange={(e) => setNameUser(e.target.value)} 
          />
          <div style={{display: 'flex', gap: '10px'}}>
            <TextField
              type="text"
              sx={{flex: 2}}
              label="El nombre del local"
              value={description}
              disabled={tradeNameParams !== undefined}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              type="text" 
              sx={{flex: 1}}
              label="Tu código"
              value={tradeName}
              disabled={tradeNameParams !== undefined}
              onChange={(e) => setTradeName(e.target.value)}
            />
          </div>
          <PhoneInput
            className='NewOrderPage__phoneInput'
            defaultCountry="es"
            value={number}
            onChange={(e) => {setNumber(e)}}
          />
          {!handleOrder ? 
          <Button
            variant='contained'
            disabled={!isValid && !handleOrder}
            sx={{
              width: '100%',
              fontFamily: 'Montserrat',
              backgroundColor: '#5FC56E',
              '&:hover': {
                backgroundColor: '#5FC56E',
              },
            }}
            onClick={() => {nameUser !== '' && number.length > 5 && description !== '' ? handleSendOrder() :
          alert('Rellene todos los campos')}}
          >
            {!isValid ? 'Introduzca el número correcto' : 'Confirmar'}
          </Button>: <CircularProgress/>} 
        </Box> 
      </Modal>
    </div>
  )
}

export default FastTempleOrder