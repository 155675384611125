import React, { useMemo, useState } from 'react'
import './ProductsInfo.scss'
import { Box, Switch, Typography, styled, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import galochka from '../../../../components/assets/images/galochkaStatic.svg'

export const ProductsInfo = ({ orders }) => {
  
  const mobileView = useMediaQuery('(max-width: 798px)')
  const { t } = useTranslation()
  const IOSSwitch = styled((props) => <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />)(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(25px)',
      color: '#fff',

      '& + .MuiSwitch-track': {
        backgroundColor: '#2A2D2F',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,

    backgroundImage: `url(${galochka})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundColor: '#176318',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))

  const items = useMemo(() => {
    if (orders) {
      const uniqueItemsMap = {} // Объект для отслеживания уникальных SKU и их соответствующих элементов

      orders?.forEach((order) => {
        order?.items?.forEach((item) => {
          const key = `${item.sku}-${item.marketPriceCP}` // Формируем уникальный ключ SKU-цена

          if (!uniqueItemsMap[key]) {
            // Если элемент с таким ключом еще не существует, добавляем его в объект
            uniqueItemsMap[key] = {
              img: item.img,
              conceptio: item.name,
              // sku: item.sku,
              quantity: item.quantity,
              price: item.marketPriceCP,
              // IVA: item.IVA ? item.IVA : 0,
              // tipo: item.currentUnit ? item.currentUnit : '',
              total: (item.marketPriceCP * item.quantity).toFixed(2),
            }
          } else {
            // Если элемент с таким ключом уже существует, увеличиваем количество и общую сумму
            uniqueItemsMap[key].quantity += item.quantity
            uniqueItemsMap[key].total = (uniqueItemsMap[key]?.price * uniqueItemsMap[key]?.quantity)?.toFixed(2)
          }
        })
      })

      // Преобразуем объект обратно в массив для возврата из useMemo
      const uniqueItems = Object?.values(uniqueItemsMap)

      uniqueItems?.sort((a, b) => b.quantity - a.quantity)

      return uniqueItems
    } else {
      return []
    }
  }, [orders])

  // console.log(items);\
  
  const [isViseble, setIsViseble] = useState(!mobileView)

  return (
    <Box className='productsInfo'>
      <Box
        sx={{
          minHeight: '26px',
          fontWeight: 'bold',
          fontSize: '20px',
          color: '#286F29',
          textTransform: 'uppercase',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          padding: '5px 0',
        }}
        className='mainInfo__title'
      >
        <Box sx={{ margin: '5px' }}>
          <IOSSwitch checked={isViseble} onChange={() => setIsViseble(!isViseble)} />
        </Box>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>{t('StaticPage.topOrders.title')}</Box>
      </Box>
      {isViseble && <Box className='productsInfo__content'>
        <Box className='productsInfo__content__title'>
          <Typography className='productsInfo__content__title__text' sx={{ width: '60%', textAlign: 'center' }}>
            {t('StaticPage.topOrders.tableHeaders.name')}
          </Typography>
          <Typography className='productsInfo__content__title__text' sx={{ width: '20%', textAlign: 'center' }}>
            {t('StaticPage.topOrders.tableHeaders.quantity')}
          </Typography>
          <Typography className='productsInfo__content__title__text' sx={{ width: '20%', textAlign: 'center' }}>
            {t('StaticPage.topOrders.tableHeaders.price')}
          </Typography>
        </Box>
        <Box className='productsInfo__content__items'>
          {items?.map((item, index) => (
            <Box key={index} className='productsInfo__content__items__item'>
              <Box className='productsInfo__content__items__item__text ' sx={{ width: '60%', textAlign: 'center' }}>
                <Box sx={{ backgroundImage: `url(${item.img})` }} className='productsInfo__content__items__item__text__img' />
                <Typography className='productsInfo__content__items__item__text__conceptio'>{item.conceptio}</Typography>
              </Box>
              <Typography
                className='productsInfo__content__items__item__text'
                sx={{
                  width: '20%',
                  textAlign: 'center',
                  borderLeft: '1px solid #CCCCCC',
                  borderRight: '1px solid #CCCCCC',
                  height: '100% !important',
                }}
              >
                {item?.quantity ? parseFloat(item?.quantity)?.toFixed(2) : 0}
              </Typography>
              <Typography className='productsInfo__content__items__item__text' sx={{ width: '20%', textAlign: 'center' }}>
                {typeof item.price === 'number' ? item.price?.toFixed(2) : typeof item.price === 'string' ? item.price : 0}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>}
    </Box>
  )
}
