import unitEqual from '../constants/unitEqual';

const useFindItemByUnit = (item, currentUnit) => {
  const currentItem = unitEqual.find((el) => el.sku == item?.sku);
  console.log(currentItem);
  if (!currentItem) {
    return 1; // Item not found
  }
  if (currentUnit === 'caja') {
    return currentItem.caja;
  } else if (currentUnit === 'saco') {
    return currentItem.saco;
  } else if (currentUnit === 'band') {
    return currentItem.band;
  } else if (currentUnit === 'und') {
    return currentItem.und;
  } else {
    return 1; // Invalid current unit
  }
}

export default useFindItemByUnit