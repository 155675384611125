import React, { useEffect, useState } from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import { routerDeleverItems } from './router'
import 'normalize.css'
import CartElemInfoModal from './pages/components/ModalWindows/CartElemInfoModal/CartElemInfoModal'
import Footer from "./pages/components/Footer/Footer";
import { useSelector } from 'react-redux'
import FavoriteModal from './pages/components/ModalWindows/FavoriteModal/FavoriteModal'
import AutoDataSynhronaizer from './pages/components/AutoDataSynhronaizer/AutoDataSynhronaizer'
import ItemCardModal from './pages/components/ModalWindows/ItemCardModal/ItemCardModal'
import WarningModal from './pages/components/ModalWindows/WarningModal/WarningModal'
import AlertModal from './pages/components/ModalWindows/AlertModal/AlertModal'
import ErrorHandler from './pages/ErrorPage/ErrorHandler'
import useSendLog from './hooks/useSendLog'


const App = () => {
  const openFavoriteModal = useSelector(state => state.contextUI.uiModal.favoriteModal)
  const ItemModalData = useSelector(state => state.contextUI.uiModal.itemModal)
  const [isAlertModal, setIsAlertModal] = useState(true)
  const sendUserStats = useSendLog('User stats', { 
    device: navigator.userAgent,
    typeOfDevice: navigator.platform,
    os: navigator.oscpu,
    browser: navigator.appName,
    browserVersion: navigator.appVersion
  });

  // window.addEventListener('load', function(event) {
    
  //   sendUserStats()
  // });

  return (
    <div className='App'>
      <ErrorHandler>
        <div className='App__padding'>
          <RouterProvider router={routerDeleverItems} />
          
          {openFavoriteModal && <FavoriteModal/>}
          <CartElemInfoModal/>
          
        
          
          {/* {isAlertModal && 
          <AlertModal closeFunc={setIsAlertModal} sx={{ textAlign: 'start '}}>
            <div style={{width: '100%'}}>
              <img src={'https://i.imgur.com/PVJCQxX.png'} style={{width: '100%'}}/>
            </div>
            Hola buenas tardes!
            <br/>
            <br/>
            Nuestro horario de Reyes Magos 👑 
            <br/>
            <br/>
            - <b>lunes 06.01.25</b> - un <b>servicio corto, solo por la mañana, en limite</b> del stock 
            <br/>
            <br/>
            Por favor realicen sus pedidos respecto a las condiciones indicadas, teniendo en cuenta los días que no podemos proporcionarles servicio.
            <br/>
            <br/>
            Felices Fiestas 🎄🎁!!!
          </AlertModal>} */}
          <AutoDataSynhronaizer/>
          {ItemModalData.isShow ? <ItemCardModal card={ItemModalData.item}/> : <></>}
        </div>
      </ErrorHandler>
        <Footer/>
    </div>
  )
}

export default App